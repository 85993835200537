<template>
  <div class="contents" />
</template>

<script>
export default {
  name: 'TopView',
  beforeCreate() {
    // real-virtualページにリダイレクト
    this.$router.push('/real-virtual');
  }
}
</script>

<style scoped>
</style>
